import React, { useContext, useEffect, useRef } from "react";
import { RouterProvider } from "react-router-dom";
import routes from "./pages/route";
import "./App.css";
import { useAbandonPixel } from "./assets/FireTracking/AbandonPixel";
import { LanderPageContext } from "./context/LanderPageContext";
import { JobOfferingContext } from "./context/JobOfferringContext";

const App = () => {

  const inactivityTimeout = process.env.REACT_APP_INACTIVITY_TIMEOUT || 7200000; // Default to 2 hours if not defined

  // const workerRef = useRef(null);
  // const actionTriggered = useRef(false); // Flag to track if the action is fired
  // const fireAbandonPixel = useAbandonPixel();
  // const { uniqueUser } = useContext(LanderPageContext);
  // const { payOutRev } = useContext(JobOfferingContext);

  // eslint-disable-next-line no-undef
  if (urlTracking) {
    // eslint-disable-next-line no-undef
    urlTracking = process.env.REACT_APP_BASE_URL;
  }

  // const handleInactivity = () => {
  //   if (!actionTriggered.current && uniqueUser.current) {
  //     console.log("User has been inactive for 2 hours.");
  //     fireAbandonPixel(payOutRev.current);
  //   }
  //   actionTriggered.current = true; // Ensure the action only fires once
  // };

  // useEffect(() => {
  //   // Initialize the worker
  //   workerRef.current = new Worker(new URL("./webWorker/inactivityWorker.js", import.meta.url));
  //   // Listen for messages from the worker
  //   workerRef.current.onmessage = (event) => {
  //     if (event.data === "inactive") {
  //       handleInactivity();
  //     }
  //   };

  //   //Setting of timer 
  //   workerRef.current.postMessage({ type: "setTimer", duration: parseInt(inactivityTimeout, 10) })

  //   // Function to reset the inactivity timer
  //   const resetWorkerTimer = () => {
  //     if (workerRef.current && !actionTriggered.current) {
  //       workerRef.current.postMessage("reset");
  //     }
  //   };

  //   // Set up event listeners for user actions to reset the timer
  //   const events = ["mousemove", "keydown", "click"];
  //   events.forEach(event => window.addEventListener(event, resetWorkerTimer));

  //   // Cleanup on component unmount
  //   return () => {
  //     if (workerRef.current) {
  //       workerRef.current.postMessage("stop");
  //       workerRef.current.terminate(); // Terminate the worker on component unmount
  //     }
  //     handleInactivity();
  //     events.forEach(event => window.removeEventListener(event, () => { }));
  //   };

  // }, []);

  return <RouterProvider router={routes} />;
};

export default App;
