import { useLocation } from "react-router-dom"
import pixelFiringFunction from "../assets/FireTracking/imagePixelTracking";
import skipPixelFunction from "../assets/FireTracking/skipPixelTracking";
import applyPixelFunction from '../assets/FireTracking/applyPixelTracking';

const useQueryHook = () => {

    const location = useLocation();
    const queryString = location.search;
    const queryParams = new URLSearchParams(location?.search);

    const cid = queryParams.get('jgcid');
    const txid = queryParams.get('txid');
    const payout = queryParams.get('payout');
    const currency = queryParams.get('currency');

    const pixelFire = (et) => {
        pixelFiringFunction(cid, et, txid, payout)
    }

    const skipPixelFIre = (skipNumber) => {
        skipPixelFunction(cid, skipNumber, txid, payout)
    }

    const applyPixelFire = (applyNumber,bid) => {
        applyPixelFunction(cid, applyNumber, txid, bid, currency)
    }
    return { queryString, location, queryParams, pixelFire, skipPixelFIre, applyPixelFire }

};

export default useQueryHook;