const applyPixelFunction = (cid, applyNumber, txid = "", payout = "", currency = "") => {
  let IMG = document.createElement('img');

  // Base URL for the image source
  let src = `https://donsorsstaingham.com/postback?cid=${cid}&et=pg${applyNumber}apply`;

  // Conditionally add txid and payout if they are provided
  if (txid) {
      src += `&txid=${txid}`;
  }

  if (payout) {
      src += `&payout=${payout}`;
  }

  if (payout) {
    src += `&currency=${currency}`;
  }

  IMG.src = src;
  IMG.width = '0';
  IMG.height = '0';
  IMG.style.display = "none";
  IMG.id = 'applyTracking_';

  document.getElementById('root').appendChild(IMG);
};

export default applyPixelFunction;