import React from 'react';
import logoIcon from '../../assets/images/Jobs Genius Icon.png';
import moment from 'moment';
import './SerpJobs.scss'

function SerpJobs({ item, idx }) {
    const onApply=()=>{
        const windowAddress = item.url;
        window.open(windowAddress);
    }
    const formattedDate = (() => {
        if (!item.date) return "No date available";

        try {
            const date = moment(item.date); // Parse the date
            const now = moment();

            const hours = now.diff(date, 'hours');
            const days = now.diff(date, 'days');
            const weeks = now.diff(date, 'weeks');

            if (hours < 24) {
                return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
            } else if (days < 7) {
                return `${days} ${days === 1 ? "day" : "days"} ago`;
            } else {
                return `${weeks} ${weeks === 1 ? "week" : "weeks"} ago`;
            }
        } catch (error) {
            console.error("Error parsing date:", error);
            return "Invalid date";
        }
    })();

    return (
        <div className='job-container'>
            <div className='job-info'>
                <div className='values'>
                <div className='job-company-logo'>
                <img src={item.logo || item.logo_url || logoIcon} alt="JobGenius" className='logo-image' />
                </div>
                <div className='job-title'>
                    <div className='title'>
                        <span className='main-title' onClick={onApply}> {item.jobtitle || item.title}</span>
                        <span className='company'>{item.company} {'|'} {item.city} {item.state&&','} {item.state}</span>
                        <span className='company'>{formattedDate}</span>
                    </div>
                </div>
                </div>
                <div className='job-apply'>
                    <button onClick={onApply}>Quick apply</button>
                </div>
            </div>
        </div>
    );
}

export default SerpJobs;