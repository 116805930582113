import React, { useState } from 'react';
import logoIcon from '../../assets/images/Jobs_Genius_Logo.png';
import SearchComponent from '../../components/SearchComponent/SearchComponent';
import './SerpPages.scss';
import SerpJobs from '../../components/SerpJobs/SerpJobs';
function SerpPage() {

    const [jobDisplayArray, setJobDisplayArray] = useState([]);
    return (
        <div className='serpPage-container'>
            <div className='navigation'>
                <img src={logoIcon} alt="JobGenius" className='logo-image' />
            </div>
            <SearchComponent setJobDisplayArray={setJobDisplayArray} />
            <div className='jobDisplay-container'>
            {
                    jobDisplayArray.length === 0 ? (
                        <div style={{display:'flex', justifyContent:'center', marginTop:'2rem'}}>No results found</div>
                    ) : (
                        jobDisplayArray.map((item, idx) => (
                            <SerpJobs item={item} idx={idx} key={idx} />
                        ))
                    )
                }
            </div>
        </div>
    );
}

export default SerpPage;