import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { JobApi } from '../../api/jobApi';
import useQueryHook from '../../customHooks/useQueryHook';
import './SearchComponent.scss';
import { Spin } from 'antd';

const MILES_TO_KILOMETERS = 1.609344;

function SearchComponent({ setJobDisplayArray }) {
    const navigate = useNavigate();
    const { queryParams } = useQueryHook();
    const isTesting = queryParams.get('testing');
    const isTesting2 = queryParams.get('testing2');
    const isTesting3 = queryParams.get('testing3');
    const zip = queryParams.get('zip');

    const [keyword, setKeyword] = useState(queryParams.get('keyword') || "");
    const [jobLocation, setJobLocation] = useState(queryParams.get('job_location') || "");
    const [loading, setLoading] = useState(false);

    const searchClick = async () => {
        setLoading(true);
        try {
            const { data } = await JobApi.serpPageJobs({
                keyword,
                location: jobLocation,
                testing: isTesting || false,
                testing2: isTesting2 || false,
                testing3: isTesting3 || false,
            });

            const { talroo, talent } = data || {};
            const jobs = talroo ? await fetchTalrooJobs(talroo) : [];
            const talrooJobs = talroo ? jobs : [];
            const talentJobs = talent?.jobs || [];

            setJobDisplayArray(
              talroo?.id < talent?.id
                ? [...talrooJobs, ...talentJobs]
                : [...talentJobs, ...talrooJobs]
            );
        } catch (error) {
            console.error("Error fetching talroo and talent jobs:", error);
        }finally {
            setLoading(false);
        }
    };

    const fetchTalrooJobs = async (talroo) => {
        const params = [
            keyword && `q=${encodeURIComponent(keyword)}`,
            jobLocation ? `l=${encodeURIComponent(jobLocation)}` : zip && `l=${encodeURIComponent(zip)}`,
            talroo?.ip && `ip=${encodeURIComponent(talroo.ip)}`,
            talroo?.distance && `d=${encodeURIComponent(talroo?.distance * MILES_TO_KILOMETERS)}`,
            "format=json",
            "logo=1",
            "link=1",
            `limit=${encodeURIComponent(talroo.limit)}`,
        ].filter(Boolean).join("&");

        const apiUrl = `${talroo.url}&${params}`;

        try {
            const response = await axios.get(apiUrl);
            return response.data?.jobs || [];
            
        } catch (error) {
            console.error("Error fetching Talroo jobs:", error);
            return [];
        }
        
    };
    useEffect(() => {
        searchClick();
    }, []);

    return (
        <div className='search-container'>
            <div className='search-children'>
                <div className='search-heading'>
                    <h1 className='search-title'>Search by job title</h1>
                </div>
                <div className="search-input">
                    <div>
                        <input
                            type="text"
                            className="input-box"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                        />
                    </div>
                    <div className="divider"></div>
                    <div>
                        <input
                            type="text"
                            className="input-box"
                            value={jobLocation}
                            onChange={(e) => setJobLocation(e.target.value)}
                        />
                    </div>
                    <div className="search-icon" onClick={searchClick}>
                        {loading ? (
                            <Spin spinning={loading}></Spin>
                        ) : (
                            <img
                                width="40"
                                height="40"
                                src="https://img.icons8.com/ios/50/search--v1.png"
                                alt="search-icon"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchComponent;
